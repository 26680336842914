html, body {
  height: 100vh;
}

body {
  background-color: #ffffff;
  color: #2c302f;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

h1, h2, h3 {
  font-weight: 300;
}

nav {
  display: block;
  background-color: black;
  color: white;
  padding: 7px;
}

nav > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
}

nav > ul > li {
  display: block;
}

nav > ul > li > a {
  display: block;
  color: beige;
  font-size: 18px;
  padding: 10px 20px;
  font-weight: bold;
  text-shadow: 0 -1px black;
  text-decoration: none;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 3px;
  background-color: rgba(0,0,0,0.1);
}

.page-title {
  font-size: 6em;
  font-weight: bold;
  text-align: center;
  word-break: break-all;
  line-height: 0.85;
}

.page-title > i {
  display: block;
  font-size: 2em;
  margin-bottom: 0.3em;
}

footer {
  background: #282828;
  color: beige;
  flex: 1 0 auto;
  padding-top: 20px;
}

footer a {
  color: beige;
}

footer > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
}

footer > ul > li {
  display: block;
}

footer > ul > li > a {
  display: block;
  font-size: 21px;
  padding: 10px 20px;
}

.spacer {
  flex: 2 1 auto;
}

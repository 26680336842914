#map {
  height: 100%;
}

.locate-btn {
  display: block;
  cursor: pointer;
  z-index: 1000000;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
  border: 0px solid black;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.map-wrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.auto-search {
  margin-top: 10px;
  position: relative;
  z-index: 5000;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

#search {
  width: 100%;
  border-radius: 5px;
  height: 30px;
  padding: 0 10px;
  border: none;
  resize: none;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.auto-search-wrapper {
  display: block;
  position: relative;
}

#georesults {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 5000;
  background-color: white;
}

.georesult-option {
  overflow: hidden;
  display:inline-block;
  white-space: nowrap;
  cursor: pointer;
}

.georesult-option:hover {
  background-color: #22437e;
  color: white;
}

#clear-search {
  color: black;
  position: absolute;
  top: 0;
  right: -20px;
  cursor: pointer;
}

.invisible {
  display: none;
}